const backlink = document.querySelector('.js-backlink');

if (backlink) {
  if (
    window.history.length > 1 &&
    document.referrer.indexOf(window.location.host) !== -1
  ) {
    backlink.href = '';
    backlink.addEventListener('click', (e) => {
      e.preventDefault();
      window.history.back();
    });
  }
}
