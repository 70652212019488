import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Register ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

// Variables
const markup = {
  siteFooter: document.querySelector('.c-footer'),
  siteTease: document.querySelector('.c-tease'),
  projectIntro: document.querySelector('.c-project__intro'),
  projectTitle: document.querySelector('h2'),
  projectParallax: document.querySelector('.parallax'),
  pageAnim: document.querySelector('.js-venn'),
};

function loaderAnim() {
  // Reset to top of doc on load to prevent any weirdness
  window.scrollTo(0, 0);

  // Animate loader out
  document.body.classList.add('loading-complete');

  // Remove loader classes
  setTimeout(() => {
    document.body.classList.remove('loading', 'loading-complete');
  }, 1200);
}

function teaseAnim() {
  // Bring it in when center of item, hits bottom of viewport
  ScrollTrigger.batch('.c-tease', {
    start: 'top bottom',
    once: true,
    onEnter: (batch) =>
      gsap.to(batch, {
        opacity: 1,
        x: 0,
        stagger: 0.3,
        ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
      }),
  });
}

function footerAnim() {
  gsap.set('.c-footer', {
    backgroundColor: '#000000',
  });

  gsap.set('.c-footer__cta, .c-footer__details', {
    opacity: 0,
    yPercent: -100,
  });

  gsap.to('.c-footer', {
    backgroundColor: '#1b2535',
    scrollTrigger: {
      trigger: '.c-footer',
      refreshPriority: 0,
      scrub: true,
    },
  });

  gsap.to('.c-footer__cta, .c-footer__details', {
    opacity: 1,
    yPercent: 0,
    scrollTrigger: {
      end: 'bottom bottom',
      trigger: '.c-footer',
      refreshPriority: 0,
      scrub: true,
    },
  });
}

function projectSlideIn() {
  // Hide the content sliding in
  gsap.set(
    '.c-project__body > *:not(.wp-block-cover):not(.wp-block-pullquote):not(.parallax), .c-project__body > .wp-block-pullquote > blockquote, .c-project__body > .wp-block-cover > .wp-block-cover__inner-container',
    {
      x: 30,
      opacity: 0,
    },
  );

  // Bring it in when center of item, hits bottom of viewport
  ScrollTrigger.batch(
    '.c-project__body > *:not(.wp-block-cover):not(.wp-block-pullquote)',
    {
      start: 'top center',
      once: true,
      trigger: '.c-project__body',
      refreshPriority: 1,
      onEnter: (elements) => {
        gsap.to(elements, {
          x: 0,
          opacity: 1,
          duration: 0.65,
          ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
          stagger: {
            amount: 0.75,
          },
        });
      },
    },
  );

  ScrollTrigger.batch(
    '.c-project__body > .wp-block-pullquote > blockquote, .c-project__body > .wp-block-cover > .wp-block-cover__inner-container',
    {
      start: 'top center',
      once: true,
      trigger: '.c-project__body',
      refreshPriority: 1,
      onEnter: (elements) => {
        gsap.to(elements, {
          x: 0,
          opacity: 1,
          duration: 0.65,
          ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
          stagger: {
            amount: 0.75,
          },
        });
      },
    },
  );
}

function projectParallax() {
  gsap.to('.wp-block-image.u-width--three-quarters', {
    yPercent: 30,
    ease: 'none',
    scrollTrigger: {
      trigger: '.parallax',
      scrub: true,
    },
  });

  gsap.to('.wp-block-image.u-width--half', {
    yPercent: -50,
    ease: 'none',
    scrollTrigger: {
      trigger: '.parallax',
      scrub: true,
    },
  });
}

function projectIntro() {
  const projectTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: '.c-project__header',
      pin: true,
      scrub: 0.6,
    },
  });

  projectTimeline
    .to('h2.c-project__intro-item', {
      // Stagger out the titles
      x: -30,
      autoAlpha: 0,
      duration: 0.2,
      overwrite: true,
      stagger: {
        amount: 0.15,
      },
    })
    .to('.c-project__feature', {
      // Pin the video
      width: '100%',
    });

  return projectTimeline;
}

function pageAnimation() {
  gsap.to('body.project', {
    scrollTrigger: {
      trigger: markup.pageAnim,
      toggleActions: 'play reverse play reverse',
      start: 'top top',
      end: 'bottom top',
    },
    backgroundColor: '#1b2535',
    color: '#ffffff',
  });

  const animTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: markup.pageAnim,
      start: 'top top',
      end: 'bottom center',
      scrub: 0.5,
      pin: true,
    },
  });

  animTimeline.to('.c-sectors__group-item', {
    color: '#ffffff',
  });

  animTimeline.to('.c-sectors__group-item', {
    width: '75%',
  });

  animTimeline.to(
    '.c-sectors__group-item--innovation .inner',
    {
      top: '22%',
      left: '50%',
      color: '#ff5400',
    },
    '<',
  );

  animTimeline.to(
    '.c-sectors__group-item--design .inner',
    {
      top: '33%',
      left: '33%',
      color: '#ff5400',
    },
    '<',
  );

  animTimeline.to(
    '.c-sectors__group-item--creative .inner',
    {
      top: '33%',
      left: '66%',
      color: '#ff5400',
    },
    '<',
  );

  animTimeline.to(
    '.c-sectors__group-item--technology .inner',
    {
      top: '66%',
      left: '33%',
      color: '#ff5400',
    },
    '<',
  );

  animTimeline.to(
    '.c-sectors__group-item--business .inner',
    {
      top: '66%',
      left: '66%',
      color: '#ff5400',
    },
    '<',
  );

  animTimeline.to(
    '.c-sectors__group-item--culture .inner',
    {
      top: '78%',
      left: '50%',
      color: '#ff5400',
    },
    '<',
  );

  animTimeline.to(
    '.c-sectors__group-item--tdc',
    {
      opacity: 1,
      width: '50%',
      height: '50%',
      left: '32%',
      top: '23%',
      fontSize: '3rem',
    },
    '<',
  );

  animTimeline.to(
    '.c-sectors__group-item--tdc .logo_spacer',
    {
      width: '0.1em',
    },
    '<',
  );
}

window.addEventListener('load', () => {
  const maintimeline = gsap.timeline().add(loaderAnim());

  if (markup.siteTease) {
    // Add tease reveal effect
    maintimeline.add(teaseAnim());
  }

  if (markup.projectIntro) {
    // Extract the first H2 add to the header
    markup.projectTitle.classList.add('c-project__intro-item');
    markup.projectIntro.appendChild(markup.projectTitle);

    // Add project scroll animations
    ScrollTrigger.matchMedia({
      '(min-width: 1024px)': () => {
        maintimeline.add(projectIntro()).add(projectSlideIn());
      },
    });
  }

  if (markup.projectParallax) {
    maintimeline.add(projectParallax());
  }

  if (markup.pageAnim) {
    maintimeline.add(pageAnimation());
  }

  if (markup.siteFooter) {
    // Add footer reveal effect
    maintimeline.add(footerAnim());
  }
});
