const menu = {
  header: document.querySelector('.c-header--hidden'),
};

function menuOut() {
  setTimeout(() => {
    menu.header.scroll({ top: 0, behavior: 'smooth' });
  }, 500);
}

if (menu.header) {
  // When mouse leaves menu makes sure
  // It returns to top to display logo
  menu.header.addEventListener('mouseleave', menuOut);
}
