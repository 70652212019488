import videojs from 'video.js';

const video = {
  player: [],
  clips: [...document.getElementsByClassName('js-video')],
  tease: [...document.getElementsByClassName('js-video-tease')],
};

if (video.clips) {
  video.clips.forEach((element) => {
    video.player.push(videojs(element));
  });
}

if (video.tease) {
  video.tease.forEach((element) => {
    element.addEventListener('mouseover', () => {
      videojs(element).play();
    });

    element.addEventListener('mouseout', () => {
      videojs(element).pause();
    });
  });
}
