const cursor = document.querySelector('.cursor');
const links = [...document.querySelectorAll('a')];

function mouseMoveHandler(e) {
  cursor.style.display = 'block';
  cursor.style.left = `${e.clientX - cursor.offsetWidth / 2}px`;
  cursor.style.top = `${e.clientY - cursor.offsetHeight / 2}px`;
}

function linkEnterHandler() {
  cursor.style.transform = 'scale(2.25)';
  cursor.style.opacity = '0.8';
}

function linkLeaveHandler() {
  cursor.style.transform = 'scale(1)';
  cursor.style.opacity = '1';
}

window.addEventListener('mousemove', mouseMoveHandler);

links.forEach((link) => {
  link.addEventListener('mouseenter', linkEnterHandler);
  link.addEventListener('mouseleave', linkLeaveHandler);
});
